import React from 'react';
import { ScreenType } from '../../../gql/gqlRequests';
import * as ScreenContextForms from './ScreenContextForms';
import { ScreenContextFormProps } from './ScreenContextForms';

export default function ScreenContextFormFactory(
  props: ScreenContextFormProps,
) {
  switch (props.screenType) {
    case ScreenType.Register:
      return <ScreenContextForms.RegisterScreenContextForm {...props} />;

    case ScreenType.Home:
      return <ScreenContextForms.HomeScreenContextForm {...props} />;

    case ScreenType.AudioPlayback:
      return <ScreenContextForms.AudioPlaybackScreenContextForm {...props} />;

    case ScreenType.VideoPlayback:
      return <ScreenContextForms.VideoPlaybackScreenContextForm {...props} />;

    case ScreenType.RecommendationsChannel:
      return (
        <ScreenContextForms.RecommendationsChannelScreenContextForm
          {...props}
        />
      );

    case ScreenType.AreYouStillWatching:
      return (
        <ScreenContextForms.AreYouStillWatchingScreenContextForm {...props} />
      );

    case ScreenType.SeriesDetail:
    case ScreenType.MovieDetail:
      return (
        <ScreenContextForms.SeriesOrMovieDetailScreenContextForm {...props} />
      );

    case ScreenType.LoginCode:
      return <ScreenContextForms.LoginCodeScreenContextForm {...props} />;

    case ScreenType.Welcome:
      return <ScreenContextForms.WelcomeScreenContextForm {...props} />;

    case ScreenType.HomeMiniPlayer:
      return <ScreenContextForms.HomeMiniPlayerScreenContextForm {...props} />;

    case ScreenType.Splash:
    case ScreenType.Login:
    case ScreenType.ForgotPassword:
    case ScreenType.ForgotPasswordConfirmation:
    case ScreenType.ForgotPasswordExternal:
    case ScreenType.Browse:
    case ScreenType.BrowseB:
    case ScreenType.BrowseC:
    case ScreenType.BrowseD:
    case ScreenType.Favorites:
    case ScreenType.ContinueWatching:
    case ScreenType.Search:
    case ScreenType.Upsell:
    case ScreenType.Paywall:
    case ScreenType.ManageSubscription:
    case ScreenType.Account:
    case ScreenType.TermsService:
    case ScreenType.TermsPurchase:
    case ScreenType.Faq:
    case ScreenType.PrivacyPolicy:
    case ScreenType.CcpaPrivacy:
    case ScreenType.ThirdPartySoftware:
    case ScreenType.Support:
    case ScreenType.ForceUpdate:
    case ScreenType.AppExit:
    case ScreenType.LogoutConfirmation:
    case ScreenType.DeleteAccount:
    case ScreenType.PrivacyConsent:
    case ScreenType.DeleteAccountConfirmation:
    case ScreenType.AppSettings:
    case ScreenType.ParentalPin:
    case ScreenType.ParentalPinSet:
    case ScreenType.ParentalPinForgot:
    case ScreenType.Error:
      return (
        <ScreenContextForms.SharedBackgroundImageScreenContextForm {...props} />
      );

    default:
      throw new Error('Unknown Screen Type ' + props.screenType);
  }
}
