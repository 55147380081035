import React, { Fragment, useContext, useMemo, useState } from 'react';
import { Box, FormControl, FormLabel, Text } from '@chakra-ui/react';

import { FileType, RequestError } from '../../../types';
import ImageSelectionDialog from './ImageSelectionDialog';
import ImageDropzone from './ImageDropzone';
import ImageInputPreview from './ImageInputPreview';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { parsedRequestError } from '../../../utils/errors';
import { ImageMaxSize, UploadImageResponse } from '../../../types/images';
import { hasAcceptedFileType } from '../../../utils/images';
import {
  ImageLibraryQuery,
  ImageLibraryQueryVariables,
  ImageType,
} from '../../../gql/gqlRequests';
import { imageLibraryRequest } from '../../../support/images';
import { testIds } from '../../../utils/testIds';
import { AppContext } from '../../../contexts';

export const standardImageInputWidth = '210px';
export const backgroundImageInputWidth = '536px';
export const standardImageInputHeight = '210px';
export const backgroundImageInputHeight = '302px';

const spaceBetweenLabelAndDescription = '5px';
const spaceBetweenLabelAndBox = '10px';
const spaceBetweenDescriptionAndBox = '10px';

type ImageInputProps = {
  label?: string;
  description?: string;
  hasRequiredStar?: boolean;
  imageType: ImageType;
  fileTypes?: Array<FileType>;
  value: string;
  onChange: (newKey: string) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
};

export default function ImageInput({
  label,
  description,
  hasRequiredStar = false,
  imageType,
  fileTypes = ['jpeg', 'png'],
  value: key,
  onChange,
  isInvalid = false,
  isDisabled = false,
  isLoading = false,
}: ImageInputProps) {
  const [isLibraryOpen, setIsLibraryOpen] = useState(false);
  const { appData } = useContext(AppContext);

  const imageLibraryQueryFn = useAuthRequest<
    ImageLibraryQueryVariables,
    ImageLibraryQuery
  >(imageLibraryRequest);
  const imageLibraryQuery = useQuery<ImageLibraryQuery, RequestError>({
    queryKey: ['images', imageType, appData?.account.id],
    queryFn: () =>
      imageLibraryQueryFn({
        type: imageType,
        accountId: appData?.account.id ?? '',
      }),
  });

  const acceptedImages = useMemo(
    () =>
      imageLibraryQuery.data?.images.filter((image) =>
        hasAcceptedFileType(image, fileTypes),
      ) ?? [],
    [fileTypes, imageLibraryQuery.data?.images],
  );
  const selectedImage = acceptedImages.find((image) => image.key === key);
  const isAnImageSelected = !!selectedImage;

  function updateKeyAndRefetchLibrary(response: UploadImageResponse) {
    onChange(response.image.key);
    imageLibraryQuery.refetch();
  }

  const isWaitingForUpload =
    !!key && !selectedImage && imageLibraryQuery.isFetching;

  if (imageLibraryQuery.isError)
    throw parsedRequestError(imageLibraryQuery.error);

  return (
    <Fragment>
      <ImageSelectionDialog
        isOpen={isLibraryOpen}
        images={acceptedImages}
        onSelect={onChange}
        onClose={() => setIsLibraryOpen(false)}
      />

      <FormControl
        data-testid={isInvalid ? testIds.invalid_image_input : ''}
        isDisabled={isDisabled}
      >
        {label && (
          <FormLabel margin="0" _disabled={{}}>
            <Text
              textStyle="subtitle3"
              paddingBottom={
                description
                  ? spaceBetweenLabelAndDescription
                  : spaceBetweenLabelAndBox
              }
            >
              {label}
              {hasRequiredStar && (
                <Text
                  as="span"
                  textColor="system.error.700"
                  paddingLeft="5px"
                  data-testid={testIds.required}
                >
                  *
                </Text>
              )}
            </Text>
          </FormLabel>
        )}

        {description && (
          <Text
            textStyle="bodyCopySmall"
            textColor="neutrals.brandGrey.500"
            paddingBottom={spaceBetweenDescriptionAndBox}
          >
            {description}
          </Text>
        )}

        <Box hidden={isAnImageSelected}>
          <ImageDropzone
            openLibrary={() => setIsLibraryOpen(true)}
            onSuccess={updateKeyAndRefetchLibrary}
            imageType={imageType}
            fileTypes={fileTypes}
            maxSize={ImageMaxSize[imageType]}
            isDisabled={
              isDisabled ||
              isAnImageSelected ||
              imageLibraryQuery.isLoading ||
              isWaitingForUpload
            }
            isInvalid={isInvalid}
            isLoading={isLoading}
          />
        </Box>

        {isAnImageSelected && (
          <ImageInputPreview
            image={selectedImage}
            imageType={imageType}
            clearImage={() => onChange('')}
            isDisabled={isDisabled}
            isInvalid={isInvalid}
            isLoading={isLoading}
          />
        )}
      </FormControl>
    </Fragment>
  );
}
