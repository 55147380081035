import { getUniqueEntries } from '.';
import { Image } from '../gql/gqlRequests';
import { AcceptedMimeType, FileType } from '../types';

export const appLogoHeight = '55px';

export const renderFileTypes = (fileTypes: FileType | FileType[]) => {
  const fileTypeArray = getUniqueEntries([fileTypes].flat());
  const isPlural = fileTypeArray.length !== 1;

  if (fileTypes.length === 0) {
    throw new Error('At least one file type must be provided');
  }

  return `Supported file format${isPlural ? 's' : ''}: ${fileTypeArray.join(
    ', ',
  )}.`;
};

export const acceptedFileTypes = (
  fileTypes: FileType | FileType[],
): { [key: string]: string[] } => {
  const fileTypeArray = getUniqueEntries([fileTypes].flat());
  return fileTypeArray.reduce(
    (acc, type) => ({
      ...acc,
      [imageMimeType(type)]: [`.${type}`],
    }),
    {},
  );
};

export function hasAcceptedFileType(image: Image, acceptedTypes: FileType[]) {
  const acceptedMimeTypes = acceptedTypes.map((type) => imageMimeType(type));
  return acceptedMimeTypes.includes(image.mimeType);
}

// A simple function to convert a string like "png" to "image/png"
function imageMimeType(type: FileType) {
  return `image/${type}${type === 'svg' ? '+xml' : ''}`;
}

export function extensionMimeType(mimeType: AcceptedMimeType): FileType {
  const type = mimeType.split('/')[1] as FileType;
  if (type.includes('svg')) return 'svg';
  return type;
}
