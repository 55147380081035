import React, { Fragment, useContext, useState } from 'react';
import Dropzone, { FileRejection, FileWithPath } from 'react-dropzone';
import { Box, Center, Image, Input, Stack, Text } from '@chakra-ui/react';
import { FileType } from '../../../types';
import { acceptedFileTypes, renderFileTypes } from '../../../utils/images';
import publishIcon from '../../../assets/upload.svg';
import CreateButton from '../buttons/CreateButton';
import { ImageType } from '../../../gql/gqlRequests';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { uploadImageRequest } from '../../../support/images';
import { DropzoneError, UploadImageResponse } from '../../../types/images';
import {
  backgroundImageInputWidth,
  standardImageInputWidth,
  backgroundImageInputHeight,
  standardImageInputHeight,
} from './ImageInput';
import { strings } from '../../../utils/strings';
import { getFileSizeText } from '../../../utils/file';
import { getUploadErrorMessagesText } from '../../../utils/errors';
import SmallCreateButton from '../buttons/SmallCreateButton';
import { AppContext } from '../../../contexts';
import Progress from '../Progress';

const spaceBetweenBoxAndFileFormats = '5px';

type ImageDropzoneProps = {
  onSuccess: (response: UploadImageResponse) => void;
  imageType: ImageType;
  fileTypes: FileType[];
  maxSize: number;
  openLibrary: () => void;
  isDisabled?: boolean;
  isInvalid?: boolean;
  isLoading?: boolean;
};

export default function ImageDropzone({
  onSuccess,
  imageType,
  fileTypes,
  maxSize,
  openLibrary,
  isDisabled = false,
  isInvalid = false,
  isLoading = false,
}: ImageDropzoneProps) {
  /**
   * This allows us to disable the dropzone while the cursor is over the
   * 'Select from library' button. Otherwise, both the button and the file
   * upload would be triggered on clicking the button.
   */
  const [isCursorOnButton, setIsCursorOnButton] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const isDropzoneDisabled = isDisabled || isCursorOnButton;
  const imageUploadFn = useAuthRequest(uploadImageRequest);
  const { appData } = useContext(AppContext);

  async function tryToUploadFile(
    [file]: FileWithPath[],
    rejectedFiles: FileRejection[],
  ) {
    if (rejectedFiles.length > 1) {
      setErrorMessage(strings.errors.tooManyImages);
      return;
    }
    if (rejectedFiles.length === 1) {
      const [rejectedFile] = rejectedFiles;
      if (rejectedFile.errors[0].code === DropzoneError.FILE_TOO_LARGE) {
        setErrorMessage(
          strings.errors.fileSizeExceeded({ size: getFileSizeText(maxSize) }),
        );
        return;
      }
      if (rejectedFile.errors[0].code === DropzoneError.FILE_INVALID_TYPE) {
        setErrorMessage(renderFileTypes(fileTypes));
        return;
      }
    }
    if (!file) {
      setErrorMessage(strings.errors.generic);
      return;
    }
    const formData = new FormData();

    formData.append('imageFile', file);

    try {
      setErrorMessage('');
      const imageUploadResponse = await imageUploadFn({
        formData,
        imageType,
        acconutId: appData?.account.id ?? '',
      });
      onSuccess(imageUploadResponse);
    } catch (error) {
      setErrorMessage(getUploadErrorMessagesText(error));
    }
  }

  if (imageType === ImageType.Standard) {
    return (
      <Fragment>
        <Dropzone
          onDrop={tryToUploadFile}
          accept={acceptedFileTypes(fileTypes)}
          disabled={isDropzoneDisabled}
          maxFiles={1}
          maxSize={maxSize}
        >
          {({
            getRootProps,
            getInputProps,
            isFocused,
            isDragAccept,
            isDragReject,
          }) => {
            const styleBorderColors = () => {
              if (isInvalid) return 'system.error.700';
              if (isFocused) return 'primary.primaryBlue.500';
              if (isDragAccept) return 'system.success.500';
              if (isDragReject) return 'system.error.700';
              if (!!errorMessage) return 'system.error.700';
              return 'neutrals.brandGrey.300';
            };
            return (
              <Center
                {...getRootProps({
                  border: !errorMessage ? '2px dashed' : '2px solid',
                  borderRadius: '10px',
                  borderColor: styleBorderColors(),
                  backgroundColor: 'neutrals.staticWhite',
                  width: standardImageInputWidth,
                  height: standardImageInputHeight,
                  _hover: {
                    borderColor:
                      !!errorMessage || isInvalid
                        ? 'system.error.700'
                        : 'primary.primaryBlue.500',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  },
                  _focus: {
                    borderColor:
                      (!!errorMessage || isInvalid) && 'system.error.700',
                  },
                  _active: {
                    borderColor:
                      (!!errorMessage || isInvalid) && 'system.error.700',
                  },
                  transition: 'all 150ms ease-in-out',
                })}
              >
                {isLoading && (
                  <Center
                    position={'absolute'}
                    height={standardImageInputHeight}
                    width={standardImageInputWidth}
                    zIndex={1}
                    backgroundColor="neutrals.brandGrey.300"
                    borderRadius="10px"
                  >
                    <Progress />
                  </Center>
                )}
                <Input
                  {...getInputProps({
                    type: 'file',
                    'data-testid': 'image-dropzone',
                  })}
                />
                <Stack spacing="15px">
                  <Center>
                    <Image src={publishIcon} width="40px" height="40px" />
                  </Center>
                  <Text
                    textStyle="bodyCopySmall"
                    textAlign="center"
                    textColor="neutrals.brandGrey.500"
                  >
                    <Text
                      as="span"
                      textDecoration="underline"
                      textUnderlineOffset="5px"
                    >
                      {strings.common.browseFile}
                    </Text>{' '}
                    {strings.common.toUpload}
                    <br />
                    {strings.common.or}
                  </Text>
                  <Box
                    onMouseEnter={() => setIsCursorOnButton(true)}
                    onMouseLeave={() => setIsCursorOnButton(false)}
                  >
                    <SmallCreateButton
                      label={strings.common.selectFromLibrary}
                      onClick={openLibrary}
                      isDisabled={isDisabled}
                    />
                  </Box>
                </Stack>
              </Center>
            );
          }}
        </Dropzone>
        <Text
          align="left"
          textStyle="bodyCopySmall"
          textColor={
            !errorMessage ? 'neutrals.brandGrey.500' : 'system.error.700'
          }
          paddingTop={spaceBetweenBoxAndFileFormats}
        >
          {!errorMessage ? renderFileTypes(fileTypes) : errorMessage}
        </Text>
      </Fragment>
    );
  }

  if (imageType === ImageType.Background || imageType === ImageType.Asset) {
    return (
      <Fragment>
        <Dropzone
          onDrop={tryToUploadFile}
          accept={acceptedFileTypes(fileTypes)}
          disabled={isDropzoneDisabled}
          maxFiles={1}
          maxSize={maxSize}
        >
          {({
            getRootProps,
            getInputProps,
            isFocused,
            isDragAccept,
            isDragReject,
          }) => {
            const styleBorderColors = () => {
              if (isInvalid) return 'system.error.700';
              if (isFocused) return 'primary.primaryBlue.500';
              if (isDragAccept) return 'system.success.500';
              if (isDragReject) return 'system.error.700';
              if (!!errorMessage) return 'system.error.700';
              return 'neutrals.brandGrey.300';
            };
            return (
              <Center
                {...getRootProps({
                  border: !errorMessage ? '2px dashed' : '2px solid',
                  borderRadius: '10px',
                  borderColor: styleBorderColors(),
                  backgroundColor: 'neutrals.staticWhite',
                  width: backgroundImageInputWidth,
                  height: backgroundImageInputHeight,
                  _hover: {
                    borderColor:
                      !!errorMessage || isInvalid
                        ? 'system.error.700'
                        : 'primary.primaryBlue.500',
                    cursor: isDisabled ? 'not-allowed' : 'pointer',
                  },
                  _focus: {
                    borderColor:
                      (!!errorMessage || isInvalid) && 'system.error.700',
                  },
                  _active: {
                    borderColor:
                      (!!errorMessage || isInvalid) && 'system.error.700',
                  },
                  transition: 'all 150ms ease-in-out',
                })}
              >
                {isLoading && (
                  <Center
                    position={'absolute'}
                    height={backgroundImageInputHeight}
                    width={backgroundImageInputWidth}
                    zIndex={1}
                    backgroundColor="neutrals.brandGrey.300"
                    borderRadius="10px"
                  >
                    <Progress />
                  </Center>
                )}
                <Input
                  {...getInputProps({
                    type: 'file',
                    'data-testid': 'image-dropzone',
                  })}
                />
                <Stack spacing="15px">
                  <Center>
                    <Image src={publishIcon} />
                  </Center>
                  <Text
                    textStyle="bodyCopy"
                    textAlign="center"
                    textColor="neutrals.brandGrey.500"
                  >
                    <Text
                      as="span"
                      textDecoration="underline"
                      textUnderlineOffset="5px"
                    >
                      {strings.common.browseFile}
                    </Text>{' '}
                    {strings.common.toUpload}
                    <br />
                    {strings.common.or}
                  </Text>
                  <Box
                    onMouseEnter={() => setIsCursorOnButton(true)}
                    onMouseLeave={() => setIsCursorOnButton(false)}
                  >
                    <CreateButton
                      label={strings.common.selectFromLibrary}
                      onClick={openLibrary}
                      withoutIcon
                      isDisabled={isDisabled}
                    />
                  </Box>
                </Stack>
              </Center>
            );
          }}
        </Dropzone>
        <Text
          align="left"
          textStyle="bodyCopySmall"
          textColor={
            !errorMessage ? 'neutrals.brandGrey.500' : 'system.error.700'
          }
          paddingTop={spaceBetweenBoxAndFileFormats}
        >
          {!errorMessage ? renderFileTypes(fileTypes) : errorMessage}
        </Text>
      </Fragment>
    );
  }

  // won't ever happen because we have both image types handled before
  return null;
}
