import React from 'react';
import { Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import {
  ImageType,
  UpdateConfigAssetMutationVariables,
  UpdateConfigAssetMutation,
} from '../../gql/gqlRequests';
import useAuthRequest from '../../hooks/useAuthRequest';
import { updateConfigAssetRequest } from '../../support/platforms';
import { RequestError } from '../../types';
import ImageInput from './inputs/ImageInput';

interface ImageAssetProps {
  configId: string;
  label: string;
  description?: string;
  imageType: ImageType;
  assetName: string;
  value?: string;
  isDisabled: boolean;
  updateImageKey: (assetName: string, imageKey?: string) => void;
}
export function ImageAsset({
  configId,
  label,
  description = '',
  imageType,
  assetName,
  value,
  isDisabled,
  updateImageKey,
}: ImageAssetProps) {
  const updateConfigAssetMutationFn = useAuthRequest<
    UpdateConfigAssetMutationVariables,
    UpdateConfigAssetMutation
  >(updateConfigAssetRequest);
  const updateConfigAssetMutation = useMutation<
    UpdateConfigAssetMutation,
    RequestError,
    UpdateConfigAssetMutationVariables
  >({
    mutationFn: updateConfigAssetMutationFn,
  });

  const updateAsset = async (assetName: string, imageKey?: string) => {
    await updateConfigAssetMutation.mutateAsync({
      id: configId,
      asset: {
        asset: assetName,
        imageKey,
      },
    });
    updateImageKey(assetName, !imageKey ? undefined : imageKey);
  };
  return (
    <Box>
      <ImageInput
        label={label}
        description={description}
        imageType={imageType}
        value={value ?? ''}
        isDisabled={isDisabled || updateConfigAssetMutation.isLoading}
        onChange={(imageKey) =>
          updateAsset(assetName, !imageKey ? undefined : imageKey)
        }
        isLoading={updateConfigAssetMutation.isLoading}
      />
    </Box>
  );
}
