import React, {
  Fragment,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { camelCaseEnum, displayScreenType, scrollToTop } from '../../../utils';
import {
  ActionType,
  FormType,
  RequestError,
  RequestErrorCode,
} from '../../../types';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import StandardInput from '../../common/inputs/StandardInput';
import Select from '../../common/inputs/Select';
import CheckboxGroup, { Checkbox } from '../../common/inputs/CheckboxGroup';
import {
  AppContext,
  EnvironmentContext,
  PlatformsContext,
} from '../../../contexts';
import Form, { FormButtons, FormDivider, FormError } from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import Toggle from '../../common/inputs/Toggle';
import {
  ScreenContextsInput,
  Screen_ScreenFeedInputWithId,
} from '../../../types/screens';
import {
  createScreenRequest,
  screenRequest,
  screensRequest,
  updateScreenRequest,
} from '../../../support/screens';
import ScreenContextFormFactory from './ScreenContextFormFactory';
import {
  createDefaultKeyValuePairs,
  createKeyValuePairs,
  createTranslationsArray,
  doesScreenTypeExistOnPlatforms,
  extractImageKeys,
  hasLocalizations,
  hasScreenFeeds,
  keepOnlyApplicableProperties,
  listRequiredScreenContexts,
  validateScreenFeeds,
  validateScreenLocalizations,
} from '../../../utils/screens';
import makeReorderedArray from '../../../utils/reordering';
import {
  AppThemeBackgroundColorQuery,
  AppThemeBackgroundColorQueryVariables,
  CreateScreenInput,
  CreateScreenMutation,
  CreateScreenMutationVariables,
  ImageLayout,
  ScreenQuery,
  ScreenQueryVariables,
  ScreenType,
  Screen_ScreenFeedInput,
  ScreensQuery,
  ScreensQueryVariables,
  SubscriptionProduct,
  UpdateScreenMutation,
  UpdateScreenMutationVariables,
} from '../../../gql/gqlRequests';
import ScreenFeedsInput from './ScreenFeedsInput';
import { appThemeBackgroundColorRequest } from '../../../support/theme';
import ScreenLocalizationForm from './ScreenLocalizationForm';
import { LocaleCode } from '../../../types/locale';
import useEnabledSubscriptions from '../../../hooks/useEnabledSubscriptions';
import { Image } from '@chakra-ui/react';
import { platformIcon } from '../../../utils/platforms';
import { strings } from '../../../utils/strings';

// reducer //

type CoreInputState = Pick<
  CreateScreenInput,
  'name' | 'platforms' | 'allowAppRefresh'
> & {
  type: ScreenType | null;
  // store as key-value object instead of array to simplify adding/removing/updating
  translations: Record<string, string>;
  // TODO v1.1: store default color in separate property to avoid repassing multiple times
  // defaultColor: string;
};

/**
 * The screen context will be stored in a common property, regardless
 * of the screen type. It will have default values for all possible
 * properties, across all possible screen contexts. When the form is
 * submitted, those sub-properties which don't apply to the chosen
 * screen type will be discarded.
 *
 * The localizations/translations will be stored in an object of key-value
 * pairs. It will have keys for all (potential) visible inputs in the form
 * for the given/selected screen type. On submission, those keys which don't
 * apply are discarded while transforming the object to an array.
 */
type InputState = CoreInputState & {
  screenContext: ScreenContextsInput;
  screenFeeds: Screen_ScreenFeedInputWithId[];
};

type InitialInput = CoreInputState & {
  screenContext: Partial<ScreenContextsInput>;
  screenFeeds: (Screen_ScreenFeedInput & { feedTitle: string })[];
};

type Action =
  | { type: ActionType.RESET; defaultUpNextBackgroundColor: string }
  | { type: ActionType.SET_ALL; state: InitialInput }
  | {
      type: ActionType.SET_BOOLEAN;
      key: keyof Pick<InputState, 'allowAppRefresh'>;
      value: boolean;
    }
  | {
      type: ActionType.SET_SCREEN_TYPE;
      key: keyof Pick<InputState, 'type'>;
      value: ScreenType;
      potentialEnabledSubscriptions: Pick<SubscriptionProduct, 'id'>[];
      defaultUpNextBackgroundColor: string;
    }
  | {
      type: ActionType.SET_STRING;
      key: keyof Pick<InputState, 'name'>;
      value: string;
    }
  | {
      type: ActionType.SET_STRINGS;
      key: keyof Pick<InputState, 'platforms'>;
      values: string[];
    }
  | {
      type: ActionType.ADD_SCREEN_FEED;
      screenFeed: Screen_ScreenFeedInput & { feedTitle: string };
    }
  | {
      type: ActionType.UPDATE_SCREEN_FEED;
      screenFeed: Screen_ScreenFeedInputWithId;
    }
  | {
      type: ActionType.REMOVE_SCREEN_FEED;
      id: string;
    }
  | {
      type: ActionType.REORDER_SCREEN_FEEDS;
      draggedId: string;
      targetId: string;
    }
  | {
      type: ActionType.PARTIALLY_UPDATE_SCREEN_CONTEXT;
      value: Partial<ScreenContextsInput>;
    }
  | {
      type: ActionType.SET_LOCALIZATION;
      key: string;
      value: string;
    };

function initialInputState(upNextBackgroundColor = ''): InputState {
  return {
    name: '',
    type: null,
    platforms: [],
    allowAppRefresh: false,
    translations: {},
    screenFeeds: [],
    screenContext: {
      backgroundImage: '',
      primaryBackgroundImage: '',
      secondaryBackgroundImage: '',
      icon: '',
      imageLayout: ImageLayout.Landscape,
      backgroundImageLayout: ImageLayout.Landscape,
      countdown: 30,
      inactivityInterval: 14400,
      progressHeartbeat: 7,
      upNextCountdown: 30,
      upNextBackgroundColor,
      passwordMinCharacters: 8,
      passwordUppercase: true,
      passwordLowercase: true,
      passwordNumber: true,
      passwordNonAlphanumeric: true,
      qrCode: false,
      pollingIntervalSeconds: 7,
      alwaysShowOnLaunch: true,
      upNextCue: 30,
      prerollAdFrequency: 3,
      autoFullScreenTransition: 20,
    },
  };
}

function inputDataReducer(state: InputState, action: Action): InputState {
  const currentUpNextBackgroundColor =
    state.screenContext.upNextBackgroundColor;
  const updatedState = { ...state };

  switch (action.type) {
    case ActionType.RESET:
      return initialInputState(action.defaultUpNextBackgroundColor);

    case ActionType.SET_ALL:
      return {
        ...action.state,
        screenFeeds: action.state.screenFeeds.map((screenFeed, index) => ({
          id: index.toString(),
          ...screenFeed,
          image: screenFeed.image ?? '',
        })),
        screenContext: {
          ...initialInputState(currentUpNextBackgroundColor).screenContext,
          ...action.state.screenContext,
        },
      };

    case ActionType.SET_BOOLEAN:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_STRING:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_STRINGS:
      updatedState[action.key] = action.values;
      return updatedState;

    case ActionType.SET_SCREEN_TYPE:
      // preserve only core values; update default localizations
      const { name, platforms, allowAppRefresh } = state;
      const translations = createDefaultKeyValuePairs(
        action.value,
        action.potentialEnabledSubscriptions,
      );
      return {
        ...initialInputState(action.defaultUpNextBackgroundColor),
        type: action.value,
        name,
        platforms,
        allowAppRefresh,
        translations,
      };

    case ActionType.PARTIALLY_UPDATE_SCREEN_CONTEXT:
      updatedState.screenContext = {
        ...updatedState.screenContext,
        ...action.value,
      };
      return updatedState;

    case ActionType.ADD_SCREEN_FEED:
      const largestExistingId = state.screenFeeds.length
        ? Math.max(...state.screenFeeds.map(({ id }) => Number(id)))
        : -1;
      const newId = (largestExistingId + 1).toString();
      updatedState.screenFeeds = [
        ...updatedState.screenFeeds,
        {
          id: newId,
          ...action.screenFeed,
          image: action.screenFeed.image ?? '',
        },
      ];
      return updatedState;

    case ActionType.UPDATE_SCREEN_FEED: {
      const indexToUpdate = updatedState.screenFeeds.findIndex(
        (screenFeed) => screenFeed.id === action.screenFeed.id,
      );
      if (indexToUpdate > -1) {
        updatedState.screenFeeds = [...updatedState.screenFeeds];
        updatedState.screenFeeds[indexToUpdate] = action.screenFeed;
      } else {
        console.error(
          'Failed to update screen feed with id ',
          action.screenFeed.id,
        );
      }
      return updatedState;
    }

    case ActionType.REMOVE_SCREEN_FEED:
      const indexToRemove = updatedState.screenFeeds.findIndex(
        (screenFeed) => screenFeed.id === action.id,
      );
      if (indexToRemove > -1) {
        updatedState.screenFeeds = [...updatedState.screenFeeds];
        updatedState.screenFeeds.splice(indexToRemove, 1);
      } else {
        console.error('Failed to remove screen feed with id ', action.id);
      }
      return updatedState;

    case ActionType.REORDER_SCREEN_FEEDS:
      const newScreenFeedOrder = makeReorderedArray(
        updatedState.screenFeeds,
        ({ id }) => id,
        action.draggedId,
        action.targetId,
      );
      updatedState.screenFeeds = newScreenFeedOrder;
      return updatedState;

    case ActionType.SET_LOCALIZATION: {
      const updatedTranslations = { ...updatedState.translations };
      updatedTranslations[action.key] = action.value;
      updatedState.translations = updatedTranslations;
      return updatedState;
    }

    default:
      throw Error('Unhandled screen form action type: ' + action);
  }
}

// screen form //

type ScreenFormProps = {
  formType: FormType;
};

export default function ScreenForm({ formType }: ScreenFormProps) {
  const navigate = useNavigate();
  const params = useParams();
  const screenId = params.screenId ?? '';
  const pageRef = useRef<HTMLDivElement>(null);
  const { environment } = useContext(EnvironmentContext);
  const { appId, appData } = useContext(AppContext);
  const { allPlatforms } = useContext(PlatformsContext);

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  function refetchAndNavigateBack() {
    // if we don't refetch, the form may have stale cached data next load
    screensQuery.refetch();
    navigateBack();
  }

  const isEditForm = formType === 'edit';

  // existing screens (to avoid duplicated types per platform)

  const screensQueryFn = useAuthRequest<ScreensQueryVariables, ScreensQuery>(
    screensRequest,
  );
  const screensQuery = useQuery<ScreensQuery, RequestError>({
    queryKey: ['screens', appId, environment],
    queryFn: () => screensQueryFn({ appId, environment }),
  });

  // localization

  const allPlatformIds = useMemo(
    () => allPlatforms.map(({ id }) => id),
    [allPlatforms],
  );
  const {
    isLoading: arePotentialSubscriptionsLoading,
    enabledSubscriptions: potentialEnabledSubscriptions,
  } = useEnabledSubscriptions(allPlatformIds);

  // app theme

  const themeBackgroundColorQueryFn = useAuthRequest<
    AppThemeBackgroundColorQueryVariables,
    AppThemeBackgroundColorQuery
  >(appThemeBackgroundColorRequest);
  const themeBackgroundColorQuery = useQuery<
    AppThemeBackgroundColorQuery,
    RequestError
  >({
    queryKey: ['themeBackgroundColor', appId, environment],
    queryFn: () => themeBackgroundColorQueryFn({ appId, environment }),
    // TODO v1.1: re-enable query
    enabled: false, // !isEditForm,
    // don't refetch; would overwrite any changes user is making to color inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: updateDefaultScreenContextColor,
  });

  // TODO v1.1: store this in the reducer's state
  const defaultUpNextBackgroundColor =
    themeBackgroundColorQuery.data?.app.theme.colors.background ?? '';

  // create

  const createMutationFn = useAuthRequest<
    CreateScreenMutationVariables,
    CreateScreenMutation
  >(createScreenRequest);
  const createMutation = useMutation<
    CreateScreenMutation,
    RequestError,
    CreateScreenMutationVariables
  >({
    mutationFn: createMutationFn,
    onSuccess: refetchAndNavigateBack,
    onError: showErrorAndScrollToTop,
  });

  // fetch & edit

  function populateInputData(data: ScreenQuery) {
    const {
      platforms,
      name,
      type,
      allowAppRefresh,
      screenFeeds,
      localization,
    } = data.screen;

    // TODO: once the back removes `order`, these will already be ordered, so won't need to sort at all
    const formattedScreenFeeds = screenFeeds
      .sort((a, b) => a.order - b.order)
      .map(
        ({
          feed,
          layout,
          itemLayout,
          action,
          refreshPolicy: { refreshOnScreenResume, refreshIntervalSeconds },
          rowItemPageSize,
          rowPageSize,
          image,
        }) => ({
          feedId: feed.id,
          feedTitle: feed.name,
          layout,
          itemLayout,
          action,
          refreshOnScreenResume,
          refreshIntervalSeconds,
          rowItemPageSize,
          rowPageSize,
          image: image?.key ?? '',
        }),
      );

    const formattedScreenContext = extractImageKeys(data.screen);

    const formattedTranslations = createKeyValuePairs(
      type,
      potentialEnabledSubscriptions,
      localization,
    );

    dispatchInputData({
      type: ActionType.SET_ALL,
      state: {
        name,
        type,
        platforms: platforms.map(({ id }) => id),
        allowAppRefresh,
        screenFeeds: formattedScreenFeeds,
        screenContext: formattedScreenContext,
        translations: formattedTranslations,
      },
    });
  }

  function updateDefaultScreenContextColor(data: AppThemeBackgroundColorQuery) {
    dispatchInputData({
      type: ActionType.PARTIALLY_UPDATE_SCREEN_CONTEXT,
      value: { upNextBackgroundColor: data.app.theme.colors.background },
    });
  }

  const screenQueryFn = useAuthRequest<ScreenQueryVariables, ScreenQuery>(
    screenRequest,
  );
  const screenQuery = useQuery<ScreenQuery, RequestError>({
    queryKey: ['form', 'screen', screenId, environment],
    queryFn: () =>
      // v1.1: won't specify locale, will get all at once
      screenQueryFn({ id: screenId, environment, locale: LocaleCode.EN }),
    // don't fetch until potential subscriptions are known, they are used in `onSuccess`
    enabled: isEditForm && !arePotentialSubscriptionsLoading,
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: populateInputData,
  });

  const updateMutationFn = useAuthRequest<
    UpdateScreenMutationVariables,
    UpdateScreenMutation
  >(updateScreenRequest);
  const updateMutation = useMutation<
    UpdateScreenMutation,
    RequestError,
    UpdateScreenMutationVariables
  >({
    mutationFn: updateMutationFn,
    onSuccess: refetchAndNavigateBack,
    onError: showErrorAndScrollToTop,
  });

  // input

  const [inputData, dispatchInputData] = useReducer(
    inputDataReducer,
    // note: default color won't have loaded yet when this runs
    '',
    initialInputState,
  );

  function changeScreenTypeAndClearError(
    e: React.ChangeEvent<HTMLSelectElement>,
  ) {
    setErrorMessage('');
    dispatchInputData({
      type: ActionType.SET_SCREEN_TYPE,
      key: 'type',
      value: e.target.value as ScreenType,
      potentialEnabledSubscriptions: potentialEnabledSubscriptions ?? [],
      defaultUpNextBackgroundColor,
    });
  }
  function addScreenFeed(
    screenFeed: Screen_ScreenFeedInput & { feedTitle: string },
  ) {
    dispatchInputData({ type: ActionType.ADD_SCREEN_FEED, screenFeed });
  }
  function updateScreenFeed(screenFeed: Screen_ScreenFeedInputWithId) {
    dispatchInputData({ type: ActionType.UPDATE_SCREEN_FEED, screenFeed });
  }
  function removeScreenFeed(id: string) {
    dispatchInputData({ type: ActionType.REMOVE_SCREEN_FEED, id });
  }
  function reorderScreenFeeds(draggedId: string, targetId: string) {
    dispatchInputData({
      type: ActionType.REORDER_SCREEN_FEEDS,
      draggedId,
      targetId,
    });
  }
  function partiallyUpdateScreenContext(value: Partial<ScreenContextsInput>) {
    dispatchInputData({
      type: ActionType.PARTIALLY_UPDATE_SCREEN_CONTEXT,
      value,
    });
  }

  function updateKeyValuePair(key: string, value: string) {
    dispatchInputData({ type: ActionType.SET_LOCALIZATION, key, value });
  }
  const {
    name,
    type,
    platforms,
    allowAppRefresh,
    screenContext,
    screenFeeds,
    translations,
  } = inputData;

  // localization

  const { isLoading: areSubscriptionsLoading, enabledSubscriptions } =
    useEnabledSubscriptions(platforms);

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    const mutation = formType === 'create' ? createMutation : updateMutation;
    return hasErrorForKey(mutation.error, key);
  }

  function appTypeOnPlatformConflict() {
    const mutation = formType === 'create' ? createMutation : updateMutation;
    return (
      mutation.error?.response.errors[0].extensions?.code ===
      `${RequestErrorCode.CONFLICT}`
    );
  }

  // frontend validation

  const isNameValid = !!name;
  const validPlatforms = allPlatformIds.filter(
    (platformId) =>
      !platforms.includes(platformId) ||
      !doesScreenTypeExistOnPlatforms(
        screensQuery.data?.screens,
        type,
        [platformId],
        screenQuery.data?.screen.id,
      ),
  );
  const arePlatformsValid = validPlatforms.length === allPlatforms.length;
  const isTypeValid = !!type && arePlatformsValid;
  const areScreenFeedsValid = !!type && validateScreenFeeds(type, screenFeeds);
  const isScreenContextValid =
    !!type &&
    listRequiredScreenContexts(type).every((key) => !!screenContext[key]);
  const areLocalizationsValid =
    !!type &&
    !!enabledSubscriptions &&
    validateScreenLocalizations(type, enabledSubscriptions, translations);
  const isInputValid =
    isNameValid &&
    isTypeValid &&
    arePlatformsValid &&
    areScreenFeedsValid &&
    isScreenContextValid &&
    areLocalizationsValid;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    const { type, screenContext, translations, ...rest } = inputData;
    // won't be null due to validation
    if (!type) throw Error('type was null');
    const screenFeedsWithoutIds = screenFeeds.map(
      ({ id: _, feedTitle: __, ...rest }) => rest,
    );
    const preparedScreenContext = keepOnlyApplicableProperties(
      type,
      screenContext,
    );

    const preparedTranslations = createTranslationsArray(
      type,
      enabledSubscriptions,
      translations,
    );
    if (formType === 'create') {
      createMutation.mutate({
        appId,
        input: {
          type,
          ...rest,
          screenFeeds: screenFeedsWithoutIds,
          [camelCaseEnum(type)]: preparedScreenContext,
          translations: preparedTranslations,
        },
      });
    } else {
      const screenCtx = Object.entries(preparedScreenContext).reduce(
        (acc, [key, value]) => {
          const initial = initialInputState()['screenContext'];
          if (key === 'qrCode' && !value) {
            value = initial[key];
          }
          if (key === 'pollingIntervalSeconds' && !value) {
            value = initial['pollingIntervalSeconds'];
          }
          return {
            ...acc,
            [key]: value,
          };
        },
        {},
      );
      updateMutation.mutate({
        id: screenId,
        environment,
        input: {
          ...rest,
          screenFeeds: screenFeedsWithoutIds,
          [camelCaseEnum(type)]: screenCtx,
          translations: preparedTranslations,
        },
      });
    }
  }

  // UI

  const title =
    formType === 'create'
      ? strings.screens.createScreen
      : strings.screens.editScreen;
  const submitText =
    formType === 'create'
      ? strings.screens.createScreen
      : strings.common.saveChanges;

  const isEditFormLoading =
    screenQuery.isLoading ||
    updateMutation.isLoading ||
    areSubscriptionsLoading ||
    screensQuery.isLoading;
  const isCreateFormLoading =
    // TODO v1.1: once the query is re-enabled, uncomment
    // themeBackgroundColorQuery.isLoading ||
    createMutation.isLoading ||
    areSubscriptionsLoading ||
    screensQuery.isLoading;

  const isLoading = isEditForm ? isEditFormLoading : isCreateFormLoading;

  if (screenQuery.isError) throw parsedRequestError(screenQuery.error);
  if (themeBackgroundColorQuery.isError)
    throw parsedRequestError(themeBackgroundColorQuery.error);

  return (
    <Page
      isForm
      withEnvSearchParam={formType === 'edit'}
      disallowProduction={formType === 'edit'}
      withBack
      title={title}
      pageRef={pageRef}
    >
      <Form hasError={!!errorMessage}>
        <FormError message={errorMessage} />
        <StandardInput
          label={strings.screens.name}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isNameValid || hasBackendError('name'))
          }
          isDisabled={isLoading}
          value={name}
          setSanitizedValue={(value) =>
            dispatchInputData({
              type: ActionType.SET_STRING,
              key: 'name',
              value,
            })
          }
        />
        <Select
          label={strings.common.type}
          isInvalid={
            isValidationActive && (!isTypeValid || hasBackendError('type'))
          }
          isDisabled={isLoading}
          isPermanentlyDisabled={formType === 'edit'}
          value={type ?? ''}
          onChange={changeScreenTypeAndClearError}
        >
          {Object.values(ScreenType).map((screenType) => (
            <option key={screenType} value={screenType}>
              {displayScreenType(screenType)}
            </option>
          ))}
        </Select>
        <CheckboxGroup<string>
          label={strings.platforms.platforms}
          isDisabled={isLoading}
          values={platforms}
          setValues={(values) =>
            dispatchInputData({
              type: ActionType.SET_STRINGS,
              key: 'platforms',
              values,
            })
          }
        >
          {appData?.platforms.map(({ id, name }) => (
            <Checkbox
              key={id}
              value={id}
              isInvalid={
                isValidationActive &&
                (!validPlatforms.includes(id) ||
                  hasBackendError('platforms') ||
                  (appTypeOnPlatformConflict() && platforms.includes(id)))
              }
              label={name}
            >
              <Image src={platformIcon(name, 'circled')} width="30px" />
            </Checkbox>
          ))}
        </CheckboxGroup>
        <Toggle
          label={strings.screens.appRefresh}
          description={strings.screens.appRefreshDescription}
          isDisabled={isLoading}
          value={allowAppRefresh}
          setValue={(value) =>
            dispatchInputData({
              type: ActionType.SET_BOOLEAN,
              key: 'allowAppRefresh',
              value,
            })
          }
        />
        {type && (
          <Fragment>
            <FormDivider />

            {hasScreenFeeds(type) && (
              <ScreenFeedsInput
                isScreenFormLoading={isLoading}
                value={screenFeeds}
                isInvalid={
                  isValidationActive &&
                  (!areScreenFeedsValid || hasBackendError('screenFeeds'))
                }
                addScreenFeed={addScreenFeed}
                updateScreenFeed={updateScreenFeed}
                removeScreenFeed={removeScreenFeed}
                reorderScreenFeeds={reorderScreenFeeds}
              />
            )}

            <ScreenContextFormFactory
              screenType={type}
              value={screenContext}
              partiallyUpdate={partiallyUpdateScreenContext}
              isScreenFormLoading={isLoading}
              isValidationActive={isValidationActive}
              error={createMutation.error || updateMutation.error}
            />

            {hasLocalizations(type) && (
              <ScreenLocalizationForm
                screenType={type}
                enabledSubscriptions={enabledSubscriptions ?? []}
                keyValuePairs={translations}
                updateKeyValuePair={updateKeyValuePair}
                isLoading={isLoading}
                isValidationActive={!!errorMessage}
              />
            )}
          </Fragment>
        )}
        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
