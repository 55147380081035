import React from 'react';
import Page from '../../common/Page';
import { strings } from '../../../utils/strings';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ScreenAction,
  ScreenItemLayout,
  ScreenLayout,
  UpdateScreenFeedMutationVariables,
  UpdateScreenFeedMutation,
  ScreenFeedDetailQueryVariables,
  ScreenFeedDetailQuery,
  ImageType,
} from '../../../gql/gqlRequests';
import useAuthRequest from '../../../hooks/useAuthRequest';
import {
  screenFeedDetailRequest,
  updateScreenFeedRequest,
} from '../../../support/screenFeed';
import { ActionType, RequestError } from '../../../types';
import { useMutation, useQuery } from '@tanstack/react-query';
import { scrollToTop } from '../../../utils';
import fastDeepEqual from 'fast-deep-equal';
import Form, { FormButtons, FormError, FormGrid } from '../../common/Form';
import FormPrompt from '../../common/FormPrompt';
import NumberInput from '../../common/inputs/NumberInput';
import Toggle from '../../common/inputs/Toggle';
import RadioGroup, { Radio } from '../../common/inputs/RadioGroup';
import useEnvironment from '../../../hooks/useEnvironment';
import TextButton from '../../common/buttons/TextButton';
import { Box, Spacer } from '@chakra-ui/react';
import { routes } from '../../../types/routes';
import ImageDropzone from '../../common/inputs/ImageDropzone';
import ImageInput from '../../common/inputs/ImageInput';

type InputStateComplete = {
  action: ScreenAction;
  itemLayout: ScreenItemLayout;
  layout: ScreenLayout;
  title?: string | null;
  order: number;
  refreshIntervalSeconds: number;
  refreshOnScreenResume: boolean;
  rowItemPageSize: number;
  rowPageSize: number;
  image: string;
  initialState: Omit<InputStateComplete, 'initialState'>;
};

type InputState = Omit<InputStateComplete, 'initialState'>;

type Action =
  | { type: ActionType.RESET }
  | { type: ActionType.SET_ALL; input: InputState }
  | {
      type: ActionType.SET_NUMBER;
      key: keyof Pick<
        InputState,
        'order' | 'refreshIntervalSeconds' | 'rowItemPageSize' | 'rowPageSize'
      >;
      value: number;
    }
  | {
      type: ActionType.SET_ACTION;
      key: keyof Pick<InputState, 'action'>;
      value: ScreenAction;
    }
  | {
      type: ActionType.SET_BOOLEAN;
      key: keyof Pick<InputState, 'refreshOnScreenResume'>;
      value: boolean;
    }
  | {
      type: ActionType.SET_ITEM_LAYOUT;
      key: keyof Pick<InputState, 'itemLayout'>;
      value: ScreenItemLayout;
    }
  | {
      type: ActionType.SET_LAYOUT;
      key: keyof Pick<InputState, 'layout'>;
      value: ScreenLayout;
    }
  | {
      type: ActionType.SET_STRING;
      key: keyof Pick<InputState, 'title' | 'image'>;
      value: string;
    };

const defaultState: InputState = {
  action: ScreenAction.Detail,
  itemLayout: ScreenItemLayout.Landscape,
  layout: ScreenLayout.Grid,
  title: '',
  order: 0,
  refreshIntervalSeconds: 0,
  refreshOnScreenResume: false,
  rowItemPageSize: 0,
  rowPageSize: 0,
  image: '',
};

function inputDataReducer(state: InputStateComplete, action: Action) {
  const updatedState: InputStateComplete = { ...state };
  switch (action.type) {
    case ActionType.RESET:
      return { ...defaultState, initialState: defaultState };
    case ActionType.SET_ALL:
      return { ...action.input, initialState: action.input };
    case ActionType.SET_ACTION:
      updatedState[action.key] = action.value;
      return updatedState;
    case ActionType.SET_BOOLEAN:
      updatedState[action.key] = action.value;
      return updatedState;
    case ActionType.SET_NUMBER:
      updatedState[action.key] = action.value;
      return updatedState;
    case ActionType.SET_ITEM_LAYOUT:
      updatedState[action.key] = action.value;
      return updatedState;
    case ActionType.SET_LAYOUT:
      updatedState[action.key] = action.value;
      return updatedState;
    case ActionType.SET_STRING:
      updatedState[action.key] = action.value;
      return updatedState;
  }
}

export function ScreenFeedForm() {
  const navigate = useNavigate();
  const params = useParams();
  const { environment } = useEnvironment();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [shouldCheckForm, setShouldCheckForm] = React.useState(true);
  const pageRef = React.useRef<HTMLDivElement>(null);
  const [inputState, dispatchInputState] = React.useReducer(inputDataReducer, {
    ...defaultState,
    initialState: defaultState,
  });

  const screenId = params.screenId ?? '';
  const appId = params.appId ?? '';
  const feedId = params.feedId ?? '';

  const navigateBack = () => {
    navigate('..', { relative: 'path' });
  };

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // settings current screenfeed
  const pupulateInputState = (data?: ScreenFeedDetailQuery) => {
    if (!data || !data.screenFeedByScreenAndFeedIds) {
      return;
    }
    const {
      __typename,
      refreshPolicy,
      id: _,
      feed: __,
      image,
      ...inputData
    } = data.screenFeedByScreenAndFeedIds;
    dispatchInputState({
      type: ActionType.SET_ALL,
      input: {
        image: image?.key ?? '',
        ...inputData,
        ...refreshPolicy,
      },
    });
  };

  //requests

  const screenFeedQueryFn = useAuthRequest<
    ScreenFeedDetailQueryVariables,
    ScreenFeedDetailQuery
  >(screenFeedDetailRequest);

  const screenFeedQuery = useQuery<ScreenFeedDetailQuery, RequestError>({
    queryKey: ['screenFeed', screenId, feedId],
    queryFn: () => screenFeedQueryFn({ screenId, feedId, environment }),
    onSuccess: pupulateInputState,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const updateMutationFn = useAuthRequest<
    UpdateScreenFeedMutationVariables,
    UpdateScreenFeedMutation
  >(updateScreenFeedRequest);

  const updateMutation = useMutation<
    UpdateScreenFeedMutation,
    RequestError,
    UpdateScreenFeedMutationVariables
  >({
    mutationFn: updateMutationFn,
    onSuccess: navigateBack,
    onError: showErrorAndScrollToTop,
  });

  const tryToSubmit = () => {
    setShouldCheckForm(false);
    try {
      const {
        refreshIntervalSeconds,
        refreshOnScreenResume,
        initialState: _,
        title: __,
        image,
        ...data
      } = inputState;
      updateMutation.mutate({
        id: screenFeedQuery.data?.screenFeedByScreenAndFeedIds?.id ?? '',
        input: {
          ...data,
          image: image || null,
          refreshPolicy: {
            refreshIntervalSeconds,
            refreshOnScreenResume,
          },
        },
      });
    } catch (_) {
      setShouldCheckForm(true);
      showErrorAndScrollToTop();
    }
  };

  const {
    action,
    itemLayout,
    layout,
    refreshIntervalSeconds,
    refreshOnScreenResume,
    rowItemPageSize,
    rowPageSize,
    image,
  } = inputState;

  const isFormDirty = React.useCallback(() => {
    const { initialState, ...rest } = inputState;
    return !fastDeepEqual(rest, initialState) ? shouldCheckForm : false;
  }, [inputState, shouldCheckForm]);

  const screenFeedData = screenFeedQuery.data?.screenFeedByScreenAndFeedIds;

  return (
    <Page
      title={strings.screenFeed.edit}
      withBack
      isForm
      isLoading={screenFeedQuery.isFetching}
      pageRef={pageRef}
    >
      {screenFeedData && (
        <>
          <Box marginTop={'2rem'}>
            <TextButton
              textStyle="h2"
              label={screenFeedData.feed.name}
              onClick={() =>
                navigate(
                  routes.appFeedDetail({
                    appId,
                    feedId: screenFeedData.feed.id,
                  }),
                )
              }
            />
            <Spacer />
          </Box>
          <Form hasError={!!errorMessage}>
            <FormPrompt isFormDirty={isFormDirty()} />
            <FormError message={errorMessage} />

            <FormGrid>
              <RadioGroup
                label={strings.screenFeed.layout}
                value={layout}
                direction="row"
                setValue={(value) =>
                  dispatchInputState({
                    type: ActionType.SET_LAYOUT,
                    key: 'layout',
                    value: value as ScreenLayout,
                  })
                }
              >
                {Object.values(ScreenLayout).map((screenLayout) => (
                  <Radio
                    key={screenLayout}
                    value={screenLayout}
                    label={screenLayout}
                  />
                ))}
              </RadioGroup>
              <RadioGroup
                label={strings.screenFeed.itemLayout}
                value={itemLayout}
                direction="row"
                setValue={(value) =>
                  dispatchInputState({
                    type: ActionType.SET_ITEM_LAYOUT,
                    key: 'itemLayout',
                    value: value as ScreenItemLayout,
                  })
                }
              >
                {Object.values(ScreenItemLayout).map((screenItemLayout) => (
                  <Radio
                    key={screenItemLayout}
                    value={screenItemLayout}
                    label={screenItemLayout}
                  />
                ))}
              </RadioGroup>
            </FormGrid>
            <RadioGroup
              label={strings.screenFeed.action}
              value={action}
              direction="row"
              setValue={(value) =>
                dispatchInputState({
                  type: ActionType.SET_ACTION,
                  key: 'action',
                  value: value as ScreenAction,
                })
              }
            >
              {Object.values(ScreenAction).map((screenAction) => (
                <Radio
                  key={screenAction}
                  value={screenAction}
                  label={screenAction}
                />
              ))}
            </RadioGroup>
            <FormGrid>
              <NumberInput
                label={strings.screenFeed.rowPageSize}
                value={rowPageSize}
                defaultValue={rowPageSize}
                onChange={(value) =>
                  dispatchInputState({
                    type: ActionType.SET_NUMBER,
                    key: 'rowPageSize',
                    value: value,
                  })
                }
              />
              <NumberInput
                label={strings.screenFeed.rowItemPageSize}
                value={rowItemPageSize}
                onChange={(value) =>
                  dispatchInputState({
                    type: ActionType.SET_NUMBER,
                    key: 'rowItemPageSize',
                    value: value,
                  })
                }
              />
            </FormGrid>
            <FormGrid>
              <NumberInput
                label={strings.screenFeed.refreshInterval}
                value={refreshIntervalSeconds}
                units={strings.screenContexts.seconds}
                onChange={(value) =>
                  dispatchInputState({
                    type: ActionType.SET_NUMBER,
                    key: 'refreshIntervalSeconds',
                    value: value,
                  })
                }
              />
              <Toggle
                label={strings.screenFeed.refreshOnScreenResume}
                value={refreshOnScreenResume ?? false}
                setValue={(value) =>
                  dispatchInputState({
                    type: ActionType.SET_BOOLEAN,
                    key: 'refreshOnScreenResume',
                    value,
                  })
                }
              />
            </FormGrid>
            <FormGrid>
              <ImageInput
                imageType={ImageType.Background}
                value={image}
                onChange={(value) => {
                  dispatchInputState({
                    type: ActionType.SET_STRING,
                    key: 'image',
                    value: value,
                  });
                }}
                label={strings.screenFeed.screenFeedImage}
              />
            </FormGrid>

            <FormButtons
              positiveLabel={strings.common.saveChanges}
              positiveIsDisabled={screenFeedQuery.isFetching}
              negativeIsDisabled={screenFeedQuery.isFetching}
              positiveOnClick={tryToSubmit}
              negativeOnClick={navigateBack}
            />
          </Form>
        </>
      )}
    </Page>
  );
}
