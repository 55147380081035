import {
  Environment,
  Feature,
  ImageType,
  PlatformAsset,
  ScreenType,
  Status,
} from '../gql/gqlRequests';
import { AcceptedMimeType, Locale } from '../types';
import { LocaleCode } from '../types/locale';
import { extensionMimeType } from './images';
import { strings } from './strings';

// todo v1.1: locales
export const temporaryLocales = ['English'];

export const descriptionCharLimit = 1000;

export function doNothing() {
  // no op
}

export function scrollToTop(ref: React.RefObject<HTMLElement>) {
  ref.current?.scrollTo({ behavior: 'smooth', top: 0 });
}

export function displayFeature(feature: Feature) {
  switch (feature) {
    // SVOD and AVOD should be all-caps
    case Feature.Svod:
    case Feature.Avod:
      return feature;

    default:
      return displayEnum(feature);
  }
}

export function displayScreenType(type: ScreenType): string {
  switch (type) {
    case ScreenType.ForgotPasswordExternal:
      return 'Forgot Password (External)';
    case ScreenType.BrowseB:
      return 'Browse (B)';
    case ScreenType.BrowseC:
      return 'Browse (C)';
    case ScreenType.BrowseD:
      return 'Browse (D)';
    case ScreenType.Faq:
      return 'FAQ';
    case ScreenType.CcpaPrivacy:
      return 'CCPA Privacy';
    case ScreenType.RecommendationsChannel:
      return 'Recommendations Channel (OS)';
    default:
      return displayEnum(type);
  }
}

export function displayEnum(name: string): string {
  return name.split('_').map(capitalizeFirstLetter).join(' ');
}

export function displayGenerationStatus(status?: Status | null) {
  switch (status) {
    case Status.Failed:
      return 'Publishing error';
    case Status.InProgress:
      return 'Publishing...';
    default:
      return '';
  }
}

export function displayRegistrationStatus(registrationStatus: boolean): string {
  return registrationStatus
    ? strings.common.registered
    : strings.common.notRegistered;
}

export function displayTFA(tfa: boolean): string {
  return tfa ? strings.common.enabled : strings.common.disabled;
}

export function displayAppRefresh(appRefresh: boolean): string {
  return appRefresh ? strings.common.activated : strings.common.notActivated;
}

export function displayMaskedPhoneNumber(phoneNumber: string): string {
  return `(***) *** - ${phoneNumber.slice(-4)}`;
}

export function displayEnvironment(environment: Environment) {
  const word = environment.slice(1).toLocaleLowerCase();
  return [environment.charAt(0).toLocaleUpperCase(), word].join('');
}

export function isLocaleRequired(locale: Locale): boolean {
  switch (locale.code) {
    case LocaleCode.EN:
      return true;
    default:
      return false;
  }
}

export function nameAsUrl(name: string) {
  return name.toLowerCase().split(' ').join('-');
}

export function camelCaseEnum(name: string): string {
  const [first, ...rest] = name.split('_');
  return [first.toLocaleLowerCase(), ...rest.map(capitalizeFirstLetter)].join(
    '',
  );
}

export function capitalizeFirstLetter(string: string): string {
  return string[0].toUpperCase() + string.slice(1).toLowerCase();
}

export function getUniqueEntries(arr: Array<any>) {
  return arr.filter((el, ind) => arr.indexOf(el) === ind);
}

export function imageTypeToURL(imageType: ImageType) {
  switch (imageType) {
    case ImageType.Background:
      return 'uploadBackgroundImage';
    case ImageType.Standard:
      return 'upload';
    case ImageType.Asset:
      return 'uploadAsset';
    default:
      throw new Error('Unknown image type');
  }
}

export function configAssetToDisplay(asset: PlatformAsset) {
  switch (asset.type) {
    default:
      return capitalizeFirstLetter(asset.type).replaceAll('_', ' ');
  }
}

export function configAssetDescriptionToDisplay(asset: PlatformAsset) {
  switch (asset.type) {
    default:
      if (!asset.metadata) return '';
      return `${asset.metadata.width} x ${
        asset.metadata.height
      } ${extensionMimeType(
        asset.metadata.ext as AcceptedMimeType,
      ).toUpperCase()}`;
  }
}

// reference: https://decipher.dev/30-seconds-of-typescript/docs/debounce/
export function debounce(fn: (...args: any[]) => void, ms = 10) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
}

export function tanstackCompareAlphanumeric(aStr: string, bStr: string) {
  const reSplitAlphaNumeric = /([0-9]+)/gm;

  // Split on number groups, but keep the delimiter
  // Then remove falsey split values
  const a = aStr.split(reSplitAlphaNumeric).filter(Boolean);
  const b = bStr.split(reSplitAlphaNumeric).filter(Boolean);

  // While
  while (a.length && b.length) {
    const aa = a.shift();
    const bb = b.shift();

    if (!aa || !bb) break; // should not get here

    const an = parseInt(aa, 10);
    const bn = parseInt(bb, 10);

    const combo = [an, bn].sort();

    // Both are string
    if (isNaN(combo[0])) {
      if (aa > bb) {
        return 1;
      }
      if (bb > aa) {
        return -1;
      }
      continue;
    }

    // One is a string, one is a number
    if (isNaN(combo[1])) {
      return isNaN(an) ? -1 : 1;
    }

    // Both are numbers
    if (an > bn) {
      return 1;
    }
    if (bn > an) {
      return -1;
    }
  }

  return a.length - b.length;
}
