import React, { Fragment } from 'react';
import { ConfigQuery, Environment, ImageType } from '../../../gql/gqlRequests';
import { HStack, Text } from '@chakra-ui/react';
import { strings } from '../../../utils/strings';
import Details, { DetailsSection } from '../../common/Details';
import { EnvironmentContext } from '../../../contexts';
import {
  configAssetDescriptionToDisplay,
  configAssetToDisplay,
} from '../../../utils';
import { ImageAsset } from '../../common/ImageAsset';

interface PlatformAssetsProps {
  configId: ConfigQuery['app']['config']['id'];
  assets: ConfigQuery['app']['config']['assets'];
  configAssets: ConfigQuery['app']['config']['configAssets'];
  refetchConfig: () => void;
}

export default function PlatformAssets({
  configId,
  assets,
  configAssets,
}: PlatformAssetsProps) {
  const { environment } = React.useContext(EnvironmentContext);
  const [configAssetsObj, setConfigAssetObj] = React.useState<
    Record<string, string | undefined>
  >({});

  React.useEffect(() => {
    const obj = configAssets.reduce<Record<string, string | undefined>>(
      (obj, curr) => {
        obj[curr.type] = curr.imageKey;
        return obj;
      },
      {},
    );
    setConfigAssetObj(obj);
  }, [configAssets]);

  const updateValuesObject = (assetName: string, imageKey?: string) =>
    setConfigAssetObj((prevState) => ({ ...prevState, [assetName]: imageKey }));

  return (
    <Fragment>
      <Details>
        <HStack spacing={0} paddingTop="40px" align="end">
          <Text textStyle="h2">{strings.platforms.assets}</Text>
        </HStack>
        {assets.length === 0 ? (
          <DetailsSection singleRow>
            <Text
              textStyle="subtitle3"
              color="neutrals.brandGrey.500"
              margin={'0 auto'}
            >
              {strings.platforms.noAssets}
            </Text>
          </DetailsSection>
        ) : (
          assets.map((asset) => (
            <ImageAsset
              assetName={asset.type}
              configId={configId}
              imageType={ImageType.Asset}
              isDisabled={environment === Environment.Production}
              label={configAssetToDisplay(asset)}
              description={configAssetDescriptionToDisplay(asset)}
              value={configAssetsObj[asset.type]}
              updateImageKey={updateValuesObject}
              key={asset.type}
            />
          ))
        )}
      </Details>
    </Fragment>
  );
}
